import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { BrowserRouter, Route } from 'react-router-dom'; 
import AboutMe from "./pages/about";
import Contact from "./pages/contact";
import Projects from "./pages/projects";
import Index from "./pages/index";
import { Helmet } from 'react-helmet';
import NavBar from './components/navigation';
import Footer from './components/footer';
import MetaTags from './components/meta-tags';
import ReactGA from 'react-ga';
import InStock from './pages/portfolio/in-stock';
import Pupato from './pages/portfolio/pupato';
import Forestr from './pages/portfolio/forestr';
import WhiteFauxTaxidermy from './pages/portfolio/white-faux-taxidermy';
import { Router } from 'react-router-dom'; 
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();
library.add(fab, far);
const trackingId = 'UA-169292304-1';

// Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId, 'auto');
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); 
      console.log("pathname" + location.pathname);// Record a pageview for the given page
    });
function App() {
  return (
  <BrowserRouter>
    <div className="App">
      <head>
        <Helmet>
        <title>Ricardo Shaffer Full-Stack Developer, Baltimore, MD.</title>
        <meta name="description" content="About Ricardo Shaffer's Background, Education, and Current Skills." />
        <meta name="title" content="Ricardo Shaffer. Baltimore, MD Full Stack Developer" />
        </Helmet>
        <MetaTags />
      </head>
  <NavBar />
  <main>
      <Route exact path="/" component={Index} />
      <Route exact path="/projects" component={Projects} history={history}/>
      <Route path="/contact" component={Contact} history={history} />
      <Route path="/about" component={AboutMe} history={history}/>
      <Route exact path="/projects/in-stock" component={InStock} history={history}/>
      <Route exact path="/projects/pupato" component={Pupato} history={history}/>
      <Route exact path="/projects/forestr" component={Forestr} history={history}/>
      <Route exact path="/projects/white-faux-taxidermy" component={WhiteFauxTaxidermy} history={history}/>
  </main>

  </div>
  <Footer/>
    </BrowserRouter>
  );
}
export default App;
