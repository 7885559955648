import React from "react";
import "../App.css";
import { VerticalTimeline }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Timeline from '../components/timeline.js';
import MetaTags from '../components/meta-tags';
import { pdfjs } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Document } from 'react-pdf';
import resumePrintable from '../images/Ricardo-Shaffer-Resume-Q220.pdf';
import resumeColor from '../images/Resume-2020-Color.pdf';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import SelfImage from '../images/Ricardo-Shaffer.png'
import '../App.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function AboutMe(props) {
  return (
    <>
    <Container>
      <Row>
        <Col sm>
          <Image className="bio-pic" aria-hidden="true" src={SelfImage} fluid />
        </Col>
        <Col sm>
          <h2 className="bio-title">I'm Ricardo Shaffer</h2>
          <p className="bio-intro">Charlie Chaplin said, "Imagination means nothing without doing"; it stands to reason that you can't '<i>do</i>' without imagination.<br/>I like to doodle. Let me rephrase, I <i>love</i> to doodle. I doodled in biochem. I doodled while researching polymorphisms. I doodle while creating websites. I doodle while designing apps.
          
          </p>
        </Col>
      </Row>
      <hr/>
      <Row>
      <h3 className="descriptive-title">Knowledge in:</h3>
        <Col sm>
                <ul className="descriptive">
                    
                    <li><FontAwesomeIcon icon={['fab', 'js-square']} /> JavaScript</li>
                    <li><FontAwesomeIcon icon={['fab', 'css3-alt']} /> CSS3</li>
                    <li><FontAwesomeIcon icon={['fab', 'html5']} /> HTML5</li>
                    <li><FontAwesomeIcon icon={['fab', 'github']} /> GitHub & SCRUM</li>
                    <li><FontAwesomeIcon icon={['fab', 'salesforce']} /> Heroku by SF</li>     
                </ul>
        </Col>
        <Col sm>
                <ul className="descriptive">
                  <li><FontAwesomeIcon icon={['fab', 'node']} /> NodeJS</li>
                  <li><FontAwesomeIcon icon={['fab', 'npm']} /> NPM Library</li> 
                  <li><FontAwesomeIcon icon={['fab', 'react']} /> React</li> 
                  <li>MySQL</li>
                  <li>MongoDB</li>
                </ul>
        </Col>
        <Col sm>
                <ul className="descriptive">
                  <li><FontAwesomeIcon icon={['fab', 'microsoft']} /> Microsoft Azure</li>
                  <li><FontAwesomeIcon icon={['fab', 'shopify']} /> Shopify </li>
                  <li><FontAwesomeIcon icon={['fab', 'adobe']} /> Ai, Ps, An, Ae.</li>
                  <li><FontAwesomeIcon icon={['fab', 'mailchimp']} /> MailChimp</li>
                  <li><FontAwesomeIcon icon={['fab', 'google']} /> Ads + Analytics.</li>
                </ul>
        </Col>
      </Row>
      <hr/>
    </Container>
    <MetaTags
      metadescription="Ricardo Shaffer's Educational & Work History. Full-Stack Developer in Baltimore, Maryland for Hire."
      metatitle="Ricardo Shaffer, Full-stack Dev. Resume"
      title="Ricardo Shaffer's Developer Resume"
      canonicalURL="http://www.ricardoshaffer.com/about"
    />
    <div>
      <h1>Education & Experience</h1>
      <p className="resume-Link">
      <a href={resumePrintable} target="_blank"><FontAwesomeIcon icon={['far', 'file']} /> View Resume</a>.
      </p>
    </div>
    <VerticalTimeline>
      <Timeline
        date="2012 - present"
        title="Marketing Director"
        subtitle="Orem, UT /Baltimore, MD"
        description="Developed & maintained multi-channel eCommerce accounts. Designed & created eCommerce website and graphics, including packaging design. Implemented cross-platform remarketing campaigns, including newsletters, social media, & ad placements."
      />
      <Timeline
        date="2020"
        title="Full-Stack Dev. at Johns Hopkins School of Engineering "
        subtitle="Baltimore, MD"
        description="Skills Gained: JS (ES5-ES6), CSS /Sass. Backend MySQl / MongoDB / Mongoose, Express.js, React.js, Node.js, API routing."
      />
            <Timeline
        date="2019-Present"
        title="Pupato.com"
        subtitle="Baltimore, MD"
        description="Founder of Pupato.com, pet care packages for our stressed, furry friends."
      />
      <Timeline
        date="2010-2015"
        title="B.S. in Biology, UVU"
        subtitle="Orem, UT"
        description="Emphasis on biological chemistry & molecular biology.
        Research / Lab Experience: Investigated SNP on (4) sites affecting the Androgen-Receptor ( X-linked) and BRCA1 (7q21) to determine a quantitative correlation on prostate cancer, Benign Prostate Hyperplasia, and 'normal' germ-line cellular DNA.
        Comparative analysis on CAG Repeats correlation on PCa+, BPH, & W+/+ samples utilizing PCR amplification & PAGE."
      />
      <Timeline
        date="May 2011 - Nov 2012"
        title="Account Manager, Boostability"
        subtitle="American Fork, UT"
        description="Managed mid-range business accounts to meet the following goals: local organic web presence, Developed Wordpress websites in conjunction with web development team, Established goals with client and content-creation team to draw focus to business objectives."
      />
      <Timeline
        date="Sept 2009 - May 2011"
        title="Co-Owner, YodelsAds"
        subtitle="Provo, UT"
        description=" Negotiated business-to-business contracts on Direct To Door Advertisements & USPS EDDM.  Implemented customized marketing plans to target specific demographics. Established daily reporting on total deliveries with GPS tracking of every home with external auditing verification."
      />
      <Timeline
        date="June 2006 - Jan 2010"
        title="Supervisor, Crexendo, Inc."
        subtitle="Provo, UT"
        description="Managed small business accounts to meet the following goals:
        - Website design (HTML / CSS) & optimization (SEO / PPC).
        - Shopping Cart logic & integration.
        As a supervisor, I trained and oversaw a team of representatives, reduced customer cancellations, and managed mid-range accounts."
      />
  </VerticalTimeline>
    </>
  )
}
 
export default AboutMe;