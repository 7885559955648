import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logo } from "../animations/logo";
function NavBar(props) {
    return (
<header className="navigation">
<Navbar collapseOnSelect expand="lg" bg="custom-nav" variant="dark">
  <Navbar.Brand href="/"><Logo /></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
    <Nav.Link href="/about">About</Nav.Link>
    <Nav.Link href="/projects">Projects</Nav.Link>
    <Nav.Link href="/contact">Contact</Nav.Link>
  </Nav>
  <Nav>
    <Nav.Link href="https://www.linkedin.com/in/ricardoshaffer/" aria-label="Linked In" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></Nav.Link>
    <Nav.Link href="https://github.com/ricardoshaffer" aria-label="GitHub" target="_blank"><FontAwesomeIcon icon={['fab', 'github-square']} /></Nav.Link>
  </Nav>
  </Navbar.Collapse>
</Navbar>
</header>
    )
}
 
export default NavBar;