import React from 'react';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function Footer(props) {
    return (
<footer className="footer-nav">
<Navbar sticky="bottom" bg="custom-nav" variant="dark">
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto footer">
    <Nav.Link href="/about">About</Nav.Link>
    <Nav.Link href="/projects">Projects</Nav.Link>
    <Nav.Link href="/contact">Contact</Nav.Link>
  </Nav>
  <Nav>
    <Nav.Link href="https://www.linkedin.com/in/ricardoshaffer/" aria-label="linked In" target="_blank"><FontAwesomeIcon icon={['fab', 'linkedin']} /></Nav.Link>
    <Nav.Link href="https://github.com/ricardoshaffer" aria-label="GitHub" target="_blank"><FontAwesomeIcon icon={['fab', 'github-square']} /></Nav.Link>
  </Nav>
  </Navbar.Collapse>
</Navbar>
</footer>
    )
}
 
export default Footer;