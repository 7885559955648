import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import NeoCard from '../components/neo-cards.js';
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import MapCode from '../images/map-code-example.png';
import PupatoExample from '../images/pupato-pet-calming-treats.png';
import FinderApp from '../images/Finder-App.png';
import MetaTags from '../components/meta-tags';
import mountainPeaks from '../images/mountains-blue.png';
import PinFallTwo from '../images/blue-pins-large.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Index(props) {
  return (

    <div>
    <MetaTags
      metadescription="Baltimore City, Maryland Web Developer: React.js, Node.js, AWS, Shopify, BigCommerce, WP, & More."
      metatitle="Ricardo Shaffer: Biologist turned Web Developer in Baltimore, MD"
      title="Ricardo Shaffer's Portfolio"
      canonicalURL="https://www.ricardoshaffer.com/"

    />
    
      {/* <h1 className="homeTitle">{"let's build."}</h1> */}
      
      <Jumbotron fluid>
    <ParallaxProvider>
  <ParallaxBanner
      className="parallax-home"
      layers={[
        {
        image: `${mountainPeaks}`,
          amount: 1.1,
      },
          // {
          //     image: `${PinFallTwo}`,
          //     amount: -0.6,
          // }
      ]}
>
    </ParallaxBanner>
  </ParallaxProvider>
      </Jumbotron>
  <div className="body-main">
  <div class="homeName">
    <h1>I'm Ricardo Shaffer</h1>
    <h2>Full-stack developer based in Baltimore, MD.</h2>
  </div>
    <Container>
  <Row>
    <Col sm>  <NeoCard
        image= {PupatoExample}
        text="Creating intuitive UX designs."
          title="Front-end design"
        /></Col>
    <Col sm>  <NeoCard
        image= {MapCode}
      text= "MySQL & MongoDB servers."
        title="Code"
        /></Col>
    <Col sm>  <NeoCard
        image={FinderApp}
        text="Full-Stack web development"
        title="Development"
        /></Col>
  </Row>
  </Container>
  <Container>
      <Row className="skill-set">
        <Col sm>
          <a href="https://skillshop.exceedlms.com/student/award/55942107" target="_blank">
            <FontAwesomeIcon icon={['fab', 'google']} />Analytics IQ Certified
          </a>
        </Col>
        <Col sm>
        <FontAwesomeIcon icon={['fab', 'react']} />Built with React.js
        </Col>
      </Row>
  </Container>
  
  </div>
    </div>
  )
}
 
export default Index;