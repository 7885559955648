import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import '../../App.css';
import InStockPhone from '../../images/mobile-phone.png';
import FinderApp from '../../images/find-it-logo.png';
import Button from 'react-bootstrap/Button';
import InStockRendering from '../../images/Find-It-Application-Developer-Baltimore-city-md.png';
import userFlowInStock from '../../images/flowchart-inStock.png';

function InStock() {
    return (
        <>
        <Container fluid className="float-center">
            <h1 className="left-align">InStock</h1>
            <h3 className="left-align">people helping people find in-store items.</h3>
            <a href="//findinventory.herokuapp.com/" rel="noopener noreferrer" target="_blank"><img src={FinderApp} width="200px" alt="find it application by Ricardo Shaffer visualization of store data"/></a>
            </Container>
            <Container fluid className="float-center">
            <Row className="justify-content-md-center">
            <Col md={6} sm className="border-right">
                <h3 className="descriptive-title">The Problem</h3>
                <p className="descriptive">We recognized inventory for key household items became a hot commodity during the 2020 COVID-19 pandemic.<br/>
                  In order to reduce exposure to the virus and save people time, we recognized people needed a quick method to see nearby inventory, understand what the inventory levels mean, and determine their proximity to the retail location.
                    </p>
            </Col>
            <Col md={6} sm className="border-right">
            <h3 className="descriptive-title">The Purpose</h3>
            <p className="descriptive">InStock is a crowd-sourced mapping tool to help people find inventory at their local, retail shops.  Sars-CoV-2 exposed weaknesses in the supply chain impacting availability of household essentials, such as hand soap, cleaning products, and bathroom tissue.</p>
            </Col>
            {/* <Col md={4} sm>
            <h3 className="descriptive-title">The Challenges</h3>
            <p className="descriptive"></p>
            </Col> */}
            </Row>
        </Container>
        <Jumbotron className="contrast-banner constrast">
              <h3 className="contrast-header contrast">Goal</h3>
                <Row className="justify-content-md-center contrast">
                    <Col md={4} sm>
                        <h4 className="contrast">Build trust</h4>
                        <p>Enable crowd-sourcing content by proximity to reduce spamming.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Minimal steps</h4>
                        <p>Reduce hesitation to use app by removing forms & login barriers.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Digestible symbols</h4>
                        <p>Render a map with both color-coding & icons for user experience.</p>
                    </Col>
                </Row>
            </Jumbotron>
            <Container>
                <img width="100%" src={userFlowInStock} alt="user flow for InStock" title="user-flow for inStock Application"/>
            </Container>
            <Container className="container-row-project">
            <h1 className="left-align">The Product</h1>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <img src={InStockPhone} width="250px" title="In Stock App. Find crowd-sourced Inventory at your local stores." alt="In Stock App. Find crowd-sourced Inventory at your local stores."/>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Interface</h3>
                    <ul className="descriptive">
                        <li>Designed to be mobile-first.</li>
                        <li>No login or membership required for user. Businesses have dashboard option to update their own inventory to gain customers or limit unjust traffic.</li>
                        <li>Utilizes geolocation to determine user location, then locates businesses nearby (*area of improvement is accuracy).</li>
                        <li>Users can submit inventory levels given they are within 1,000 meters from the location they're updating (to reduce spamming).</li>
                    </ul>
                </Col>
            </Row>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <img src={InStockRendering} width="300px" title="InStock Inventory Visualization for Client-Side Viewing." alt="InStock Inventory Visualization for Client-Side Viewing"/>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Experience</h3>
                    <ul className="descriptive">
                        <li>A recognizable character to represent the user. Designed without assigning characteristics to reduce distractions.</li>
                        <li>Pins utilize the item(s) being searched as indicator for their levels. Both easily replicated, such as empty bottles or hangers for future dev.</li>
                        <li>Pin color scheme is in high-contrast & identifiable icons for accessability consideration.</li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <Container>
        <Row>
            <Col md={6} sm className="border-right"><h3 className="descriptive-title">technologies Used:</h3>
                <ul className="descriptive">
                    <li>MySQL database stores locations upon interaction</li>
                    <li><FontAwesomeIcon icon={['fab', 'node']} /> NodeJS built using node.js, api protocols</li>
                    <li><FontAwesomeIcon icon={['fab', 'npm']} /> Utilizes passportjs, router, expressjs.</li>
                    <li><FontAwesomeIcon icon={['fab', 'github']} /> Originally created on GitHub.</li>
                    <li><FontAwesomeIcon icon={['fab', 'microsoft']} /> Built with Microsoft Azure Maps</li>
                </ul>
            </Col>
            <Col md={6} sm>
                <Button variant="custom-btn"><a href="//findinventory.herokuapp.com/" rel="noopener noreferrer" target="_blank">Visit Find-It App</a></Button>
                <Button variant="custom-btn"><a href="//github.com/ricardoshaffer/Find-it#readme" rel="noopener noreferrer" target="_blank">View Source Code</a></Button>
            </Col>
  </Row>
        </Container>
         </>
    )
}
export default InStock;