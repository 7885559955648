import * as React from "react";
import { motion } from "framer-motion";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 0, 157, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255,255, 1)"
  }
};

export const Logo = () => (
  <div className="logo-container">
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className="item-logo"
    >
      <motion.path
 d="M91.1 65.6c0 3.3-0.6 6.1-1.9 8.5c-1.3 2.4-3.1 4.3-5.3 5.8c-2.2 1.5-4.8 2.6-7.8 3.4c-3 0.7-6.1 1.1-9.3 1.1
		c-3.1 0-6.1-0.4-8.9-1.3c-2.8-0.9-5.3-2.3-7.5-4.1c-2.1-1.8-3.9-4.1-5.2-6.9c-1.3-2.8-2.1-6-2.2-9.7l13.8-0.1
		c0.1 2 0.4 3.7 0.9 5.1c0.6 1.4 1.4 2.5 2.3 3.4c1 0.9 2.1 1.5 3.3 1.9c1.2 0.4 2.5 0.6 3.9 0.6c1.3 0 2.6-0.1 3.8-0.3
		c1.2-0.2 2.4-0.6 3.3-1.1c1-0.5 1.8-1.2 2.3-2c0.6-0.8 0.8-1.9 0.8-3.2c0-1.1-0.4-2-1.1-2.8c-0.7-0.8-1.6-1.5-2.7-2.2
		c-1.1-0.7-2.4-1.3-3.9-1.8c-1.5-0.5-3-1.1-4.5-1.6c-2.3-0.8-4.7-1.7-7.2-2.6c-2.4-1-4.6-2.1-6.6-3.5c-2-1.4-3.6-3-4.8-5
		s-1.9-4.3-1.9-7c0-0.8 0-1.6 0.1-2.4c-1-0.4-1.9-0.7-2.7-0.9c-1-0.3-1.9-0.5-2.5-0.6c-0.7-0.1-1.2-0.2-1.6-0.2c-0.4 0-0.6 0-0.8 0
		c-1.7 0-3.2 0.4-4.5 1.1c-1.3 0.7-2.5 1.7-3.4 2.8c-0.9 1.2-1.6 2.5-2.1 4.1c-0.5 1.5-0.7 3.1-0.7 4.8l0.2 34.9l-13.5 0.1L13 24.9
		l10-0.1l2.3 7.2c1.8-2.8 3.6-4.9 5.5-6.3c1.9-1.4 4-2.1 6.2-2.1c0.7 0 1.8 0.1 3.3 0.4c1.5 0.3 3.4 1 5.5 2.2l0 7.7
		c0.1-0.4 0.3-0.8 0.5-1.1c1.1-2.1 2.6-3.8 4.5-5.2c1.9-1.4 4.2-2.4 6.8-3.1c2.6-0.7 5.5-1 8.5-1.1c3.4 0 6.5 0.4 9.2 1.1
		c2.8 0.8 5.2 1.9 7.1 3.4c2 1.5 3.6 3.5 4.7 5.9c1.2 2.4 1.8 5.2 2 8.5L76 42.4c-0.2-1.5-0.6-2.7-1.1-3.7c-0.6-1-1.3-1.7-2.1-2.3
		c-0.8-0.6-1.8-1-2.9-1.2c-1.1-0.2-2.2-0.4-3.4-0.4c-2.6 0-4.7 0.5-6.1 1.4c-1.4 0.9-2.1 2.2-2.1 3.7c0 1 0.4 1.8 1.1 2.6
		c0.7 0.8 1.6 1.5 2.8 2.1c1.1 0.6 2.4 1.3 3.9 1.8c1.5 0.6 3 1.1 4.6 1.7c2.3 0.8 4.7 1.7 7.2 2.7c2.4 1 4.7 2.2 6.6 3.5
		c2 1.4 3.6 2.9 4.8 4.7C90.4 61 91.1 63.1 91.1 65.6z"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          default: { duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] }
        }}
      />
    </motion.svg>
  </div>
);
