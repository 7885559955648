import React from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function MetaTags(props) {
  return (
<Helmet>

<meta name="description" content={props.metadescription} />
<meta name="title" content={props.metatitle} />
<link rel="canonical" href={props.canonicalURL}/>

<title>{props.title}</title>

</Helmet>
);
}
export default MetaTags;
