import React from "react";
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import "../App.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import PinFall from '../images/blue-pins-small.png';
import PinFallTwo from '../images/blue-pins-large.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FinderApp from '../images/In-Stock-Logo-Blue.png';
import FindMon from '../images/Find-It-Application-Developer-Baltimore.png';
import PupatoFarmer from '../images/pupato-pet-treats-farmer.png';
import PupatoClouds from '../images/pupato-clouds.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PupatoWebsite from '../images/pupato-pet-calming-treats.png';
import ParkFinder from '../images/park-finder-map.png';
import MountainRange from '../images/mountain-park-finder.png';
import MetaTags from '../components/meta-tags';
import MountainClouds from '../images/park-finder-clouds.png';
import ForestrLogo from '../images/forestr-logo-BLUE.png';
import PupatoLogo from '../images/pupato-logo-dog-treats.png';
import WFTLogo from '../images/white-faux-taxdiermy-logo.png'
import WFTTrees from '../images/white-faux-taxidermy-trees.png'
import WFTMountains from '../images/white-faux-taxidermy-mountains.png'
function Projects(props) {
  return (
    <>
        <MetaTags
      metadescription="Ricardo Shaffer. Website Developer in Baltimore City for Hire."
      metatitle="view portfolio of websites and applications developed using React.js, Node.js, & more."
      title="Ricardo Shaffer's web dev projects"
      canonicalURL="https://www.ricardoshaffer.com/projects"
    />
<Container className="project-frame">
<Card className="bg-clear text-dark">
  <ParallaxProvider>
  <ParallaxBanner
      className="parallax-sizes"
      layers={[
        {
          image: `${WFTMountains}`,
          amount: 0.0,
      },
          {
            image: `${WFTTrees}`,
            amount: 0.1,
          }
      ]}
>
    </ParallaxBanner>
  </ParallaxProvider>,
  <Card.ImgOverlay className="white-faux-taxidermy">
    <Card.Title><a href='/projects/forestr'><Image src={WFTLogo} width="200px" alt="White Faux Taxidermy, animal-friendly wall decor."></Image></a></Card.Title>
    <Button variant="wft outline-light"><a href="/projects/white-faux-taxidermy" className="wft">View More</a></Button>
    <Card.Text className="white-faux-taxidermy">
       White Faux Taxidermy, Animal-Friendly Wall & Home Decorations.
    </Card.Text>
  </Card.ImgOverlay>
</Card>
</Container>
<hr></hr>
<Container className="project-frame">
  <Card className="bg-clear text-dark">
  <ParallaxProvider>
  <ParallaxBanner
      className="parallax-sizes"
      layers={[
        {
        image: `${PinFall}`,
          amount: 0.2,
      },
          {
              image: `${PinFallTwo}`,
              amount: -0.1,
          }
      ]}
>
    </ParallaxBanner>
  </ParallaxProvider>,
  <Card.ImgOverlay>
    <Card.Title><a href='/projects/in-stock'><Image src={FinderApp} width="200px" alt="find it application by Ricardo Shaffer visualization of store data"></Image></a></Card.Title>
    <Button variant="outline-light"><a href="/projects/in-stock">View More</a></Button>
    <Card.Text>
       InStock is a prototype application that enables visualization of data, whether it's inventory level, open/closed, or immediate availability. 
    </Card.Text>
  </Card.ImgOverlay>
</Card>
</Container>
<hr></hr>
<Container className="project-frame">
<Card className="bg-clear text-dark">
  <ParallaxProvider>
  <ParallaxBanner
      className="parallax-sizes"
      layers={[
        {
          image: `${PupatoClouds}`,
          amount: -0.3,

      },
          {
            image: `${PupatoFarmer}`,
            amount: 0.1,
          }
      ]}
>
    </ParallaxBanner>
  </ParallaxProvider>,
  <Card.ImgOverlay>
    <Card.Title><a href='/projects/pupato'><Image src={PupatoLogo} width="200px" alt="Pupato.com pet treat box for cats & dogs during stressful situations"></Image></a></Card.Title>
    <Button variant="outline-light"><a href="/projects/pupato">View More</a></Button>
    <Card.Text>
       Pupato.com, a non-subscription eCommerce website delivering pet kits for stressful times.
    </Card.Text>
  </Card.ImgOverlay>
</Card>
</Container>
<hr></hr>
<Container className="project-frame">
<Card className="bg-clear text-dark">
  <ParallaxProvider>
  <ParallaxBanner
      className="parallax-sizes"
      layers={[
        {
          image: `${MountainClouds}`,
          amount: 0.3,

      },
          {
            image: `${MountainRange}`,
            amount: 0.2,
          }
      ]}
>
    </ParallaxBanner>
  </ParallaxProvider>,
  <Card.ImgOverlay>
    <Card.Title><a href='/projects/forestr'><Image src={ForestrLogo} width="200px" alt="Pupato.com pet treat box for cats & dogs during stressful situations"></Image></a></Card.Title>
    <Button variant="outline-light"><a href="/projects/forestr">View More</a></Button>
    <Card.Text>
       Find your nearby National Park for a day trip and escape into nature.
    </Card.Text>
  </Card.ImgOverlay>
</Card>
</Container>
<hr></hr>
</>
  )
}
 
export default Projects;
