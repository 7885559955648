import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import '../../App.css';
import ForestrMobile from '../../images/mobile-phone-forestr.png';
import ForestrMobileOne from '../../images/mobile-phone-forestr1.png';
import PupatoLogo from '../../images/forestr-logo-BLUE.png';
import Button from 'react-bootstrap/Button';
import ForestrDesktop from '../../images/Forestr-find-nearby-national-parks.png';
import UserFlowFindr from '../../images/flowchart-Findr.png';

function InStock() {
    return (
        <>
        <Container fluid className="float-center">
            <h1 className="left-align">Forestr</h1>
            <h3 className="left-align">Find nearby national parks for a day trip.</h3>
            <a href="https://ricardoshaffer.github.io/Park-Finder/" rel="noopener noreferrer" target="_blank"><img src={PupatoLogo} width="200px" alt="Forestr,app to find nearby national parks & distance"/></a>
            </Container>
            <Container fluid className="float-center">
            <Row className="justify-content-md-center">
            <Col md={6} sm className="border-right">
                <h3 className="descriptive-title">The Problem</h3>
                <p className="descriptive">
                    OutdoorFoundation.org reports that less than 50% of Americans participate in outdoor activities. Individuals' stated 'barriers'rank proximity and unfamiliarity at the top spot. 
                </p>
            </Col>
            <Col md={6} sm className="border-right">
            <h3 className="descriptive-title">The Purpose</h3>
            <p className="descriptive">
                Our goal encourage people to get outdoors. By creating an easy-to-use tool that utilizes the user's location and National Parks Services API to showcase nearby national parks.
            </p>
            </Col>
            {/* <Col md={4} sm>
            <h3 className="descriptive-title">The Challenges</h3>
            <p className="descriptive"></p>
            </Col> */}
            </Row>
        </Container>
        <Jumbotron className="contrast-banner constrast">
              <h3 className="contrast-header contrast">Goal</h3>
                <Row className="justify-content-md-center contrast">
                    <Col md={4} sm>
                        <h4 className="contrast">Simplicity</h4>
                        <p>Remove barriers to use the tool. Simple interface with one-click use.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Proximity</h4>
                        <p>Provide general proximity to encourage users to determine their commute.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Reason</h4>
                        <p>Utilize NPS API to provide user images & description to encourage the users to find what peaks their interests.</p>
                    </Col>
                </Row>
            </Jumbotron>
            <Container>
                <img width="100%" src={UserFlowFindr} alt="Forestr app user flow" title="Forestr app user flow"/>
            </Container>
            <Container className="container-row-project">
            <h1 className="left-align">The Product</h1>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <a href='https://ricardoshaffer.github.io/Park-Finder/' rel="noopener noreferrer" target="_blank"><img src={ForestrMobileOne} width="200px" title="Forestr App Map view of National Parks" alt="Forestr App Map view of National Parks"/></a>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Interface</h3>
                    <ul className="descriptive">
                        <li>Designed for Mobile-First.</li>
                        <li>One-Click Design Without Login.</li>
                        <li>Ensure Items are clickable both on map & on the list.</li>
                        <li>Utilize reliable external APIs (MapQuest & NPS API).</li>
                    </ul>
                </Col>
            </Row>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <a href='https://ricardoshaffer.github.io/Park-Finder/' rel="noopener noreferrer" target="_blank"><img src={ForestrDesktop} width="300px" title="InStock Inventory Visualization for Client-Side Viewing." alt="InStock Inventory Visualization for Client-Side Viewing"/></a>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Experience</h3>
                    <ul className="descriptive">
                        <li>Landing Page with enticing image & easily seen button.</li>
                        <li>High contrast colors to entice & stimulate energy (vivid orange).</li>
                        <li>Modal utilizes contemporary gradient with nature landscape.</li>
                        <li>Flight-of-the-crow distance for travel estimates.</li>
                        <li>Link to map, park hours, and park information.</li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <Container>
        <Row>
            <Col md={6} sm className="border-right"><h3 className="descriptive-title">Technologies Used:</h3>
                <ul className="descriptive">
                <li><FontAwesomeIcon icon={['fab', 'js-square']} /> Built with JavaScript.</li>
      <li><FontAwesomeIcon icon={['fab', 'adobe']} /> Adobe Illustrator designed logo.</li>
      <li><FontAwesomeIcon icon={['fab', 'github-square']} /> Built with GitHub SCRUM & Hosted with GitHub.</li>
      <li><FontAwesomeIcon icon={['fab', 'google']} /> Adwords, remarketing, analytics.</li>
                </ul>
            </Col>
            <Col md={6} sm>
                <Button variant="custom-btn"><a href="https://ricardoshaffer.github.io/Park-Finder/" rel="noopener noreferrer" target="_blank">Visit Forestr App</a></Button><br/>
                <Button variant="custom-btn"><a href="https://github.com/ricardoshaffer/Park-Finder#readme" rel="noopener noreferrer" target="_blank">View Source Code</a></Button>
                
                <br/>
            </Col>
  </Row>
        </Container>
         </>
    )
}
export default InStock;