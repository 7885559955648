import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import '../../App.css';
import PupatoInterface from '../../images/Pupato-interface.png';
import PupatoLogo from '../../images/pupato-logo-dog-treats.png';
import Button from 'react-bootstrap/Button';
import PupatoRunaway from '../../images/scared-dog-running-away-fireworks.png';
import UserFlowPupato from '../../images/flowchart-Pupato.png';

function InStock() {
    return (
        <>
        <Container fluid className="float-center">
            <h1 className="left-align">Pupato</h1>
            <h3 className="left-align">Delicious treats to distract your anxious pup & kitty.</h3>
            <a href="//findinventory.herokuapp.com/" rel="noopener noreferrer" target="_blank"><img src={PupatoLogo} width="200px" alt="Pupato.com, pet treats to distract your anxious cat or dog"/></a>
            </Container>
            <Container fluid className="float-center">
            <Row className="justify-content-md-center">
            <Col md={6} sm className="border-right">
                <h3 className="descriptive-title">The Problem</h3>
                <p className="descriptive">Our pets are part of the family; they bring us comfort, peace, and friendship. Unfortunately, pets cannot talk about their stressors.  Furthermore, pets, like humans, vary in personality, size, and emotions.<br/>Prescription medication often induces stress to pet parents as it may impact their personality & energy too much.x
                    </p>
            </Col>
            <Col md={6} sm className="border-right">
            <h3 className="descriptive-title">The Purpose</h3>
            <p className="descriptive">
                My goal was to encourage new pet-parents to understand their pets to reduce shelter abandonment or runaways triggered by stressors. The product is a pet shopping experience that focused on using treats as a tool to soothe your pet as opposed to as a solution.  Treating pets is more often to treat the pet-parent, my goal is to help pet parents understand & work with their pets instead of having their pets respond to their human's behavior.
            </p>
            </Col>
            {/* <Col md={4} sm>
            <h3 className="descriptive-title">The Challenges</h3>
            <p className="descriptive"></p>
            </Col> */}
            </Row>
        </Container>
        <Jumbotron className="contrast-banner constrast">
              <h3 className="contrast-header contrast">Goal</h3>
                <Row className="justify-content-md-center contrast">
                    <Col md={4} sm>
                        <h4 className="contrast">Build authority</h4>
                        <p>Provide comprehensible & unbiased analysis of scientific data that supports product ingredients.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Create a story</h4>
                        <p>Pet owners anthropomorphize their pets; our goal is to bring it to life pet personalities & make it relatable.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Encourage pet-parents to be patient</h4>
                        <p>Emphasize that the produdcts are not a permanent fix. Instead, we want to provide tools to create a lifelong relationship & limit shelter drop-offs.</p>
                    </Col>
                </Row>
            </Jumbotron>
            <Container>
                <img width="100%" src={UserFlowPupato} alt="user flow for Pupato, pet treats for anxious cats and dogs" title="user flow for Pupato, pet treats for anxious cats and dogs"/>
            </Container>
            <Container className="container-row-project">
            <h1 className="left-align">The Product</h1>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <a href='https://pupato.com/pages/the-pups' rel="noopener noreferrer" target="_blank"><img src={PupatoInterface} width="300px" title="Pupato.com, user flow to find your pets personality" alt="Pupato.com, user flow to find your pets personality"/></a>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Interface</h3>
                    <ul className="descriptive">
                        <li>Define the pet's behavior: fearful, hyperactive, and mischievous.</li>
                        <li>Drive customers through a story of limiting use.</li>
                        <li>Use customer's unique story to create limited remarketing based on events, like fireworks, etc.</li>
                        <li>Direct customers to checkout when added to cart as opposed to staying on page.</li>
                    </ul>
                </Col>
            </Row>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <img src={PupatoRunaway} width="300px" title="InStock Inventory Visualization for Client-Side Viewing." alt="InStock Inventory Visualization for Client-Side Viewing"/>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Experience</h3>
                    <ul className="descriptive">
                        <li>Create pet personas that are easily relatable to most pet parents, and create a story that emphasizes no-purchase required remedies.</li>
                        <li>Use cool colors that contrast the problem (dull) with the potential outcome (vivid).</li>
                        <li>Engage users through graphics & limited captions to ensure they remain engaged.</li>
                        <li>Reiterate that it is not a monthly subscription to reduce hesitation.</li>
                        <li>Use an emotional appeal to put the pet's needs first in stressful situations.</li>
                        <li>Always provide a toy that engages the pet and pet-parent to engage as opposed to simply treat.</li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <Container>
        <Row>
            <Col md={6} sm className="border-right"><h3 className="descriptive-title">Technologies Used:</h3>
                <ul className="descriptive">
                <li><FontAwesomeIcon icon={['fab', 'shopify']} /> Shopify built utilizing shopify cart.</li>
      <li><FontAwesomeIcon icon={['fab', 'adobe']} /> Adobe Illustrator & Animate designed graphics.</li>
      <li><FontAwesomeIcon icon={['fab', 'mailchimp']} /> MailChimp remarketing emails & abandoned cart optimization.</li>
      <li><FontAwesomeIcon icon={['fab', 'google']} /> Adwords, remarketing, analytics.</li>
                </ul>
            </Col>
            <Col md={6} sm>
                <Button variant="custom-btn"><a href="https://pupato.com/" rel="noopener noreferrer" target="_blank">Visit Pupato.com</a></Button>
                <br/>
                <p>*No READ-ME Available; Pupato.com is showcasing front-end design, optimization, and marketing objectives.</p>
            </Col>
  </Row>
        </Container>
         </>
    )
}
export default InStock;