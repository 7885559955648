import React, {useEffect} from "react";
import { Example } from "../animations/recoil";
import MetaTags from "../components/meta-tags";
import CaptchaContact from '../components/captcha';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pdfjs } from 'react-pdf';
import { Document } from 'react-pdf';
import ResumePDF from '../images/Ricardo-Shaffer-Resume-Q220.pdf';
import '../App.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
function Contact(props) {

  return (
    <div>
      <CaptchaContact/>
    <MetaTags
      metadescription="Contact Ricardo Shaffer in Baltimore, MD. Biologist turned Web Developer. Bridging Science to simplify data"
      metatitle="Contact Ricardo Shaffer"
      title="Contact Ricardo Shaffer"
      canonicalURL="https://www.ricardoshaffer.com/contact"
    />
      <h1>contact</h1>
<Container>
      <ListGroup className="contact-info">
        <ListGroup.Item><strong>Phone: </strong>(801) 362-9625</ListGroup.Item>
        <ListGroup.Item><strong>E-Mail: </strong>Ricardo.Ramiro.Shaffer[@]gmail.com</ListGroup.Item>
        <ListGroup.Item><FontAwesomeIcon icon={['fab', 'linkedin']} /><a href="https://www.linkedin.com/in/ricardoshaffer/" target="_blank"> LinkedIn</a></ListGroup.Item>
        <ListGroup.Item><FontAwesomeIcon icon={['fab', 'github-square']}/><a href="https://github.com/ricardoshaffer" target="_blank"> GitHub</a></ListGroup.Item>
        <ListGroup.Item><a href={ResumePDF}  target="_blank">Download Printable Resume</a> <Container>
      <Document
        file={ResumePDF}/>
  </Container></ListGroup.Item>
      </ListGroup>
  </Container>

    </div>
  )
}
 
export default Contact;