import React from 'react';
import '../App.css';
import { VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

function Timeline(props) {
  return (
<VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgba(49, 85, 133, 0.0)', color: '#fff' }}
      // contentArrowStyle={{ borderRight: '7px solid  rgba(175, 8, 139, 0.0)' }}
      date={props.date}
      iconStyle={{ background: 'rgb(221, 255, 118)', color: '#ffffff' }}
      icon=""
    >
      <h3 className="vertical-timeline-element-title">{props.title}</h3>
      <h4 className="vertical-timeline-element-subtitle">{props.subtitle}</h4>
      <p>
      {props.description}
      </p>
    </VerticalTimelineElement>
  )
}
 
export default Timeline;