import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import '../../App.css';
import InStockPhone from '../../images/mobile-phone.png';
import WFTLogo from '../../images/white-faux-taxdiermy-zebra-animal-logo-2019.png';
import Button from 'react-bootstrap/Button';
import InStockRendering from '../../images/Find-It-Application-Developer-Baltimore-city-md.png';
import userFlowInStock from '../../images/flowchart-inStock.png';
import WFTDimensions from '../../images/White-Faux-Taxidermy-Deer-Dimensions.png'
import WFTMobile from '../../images/White-Faux-Taxidermy-Mobile.png'

function WhiteFauxTaxidermy() {
    return (
        <>
        <Container fluid className="float-center">
            <h1 className="left-align">WhiteFauxTaxidermy</h1>
            <h3 className="left-align">animal-friendly home & wall decor.</h3>
            <a href="https://whitefauxtaxidermy.com" rel="noopener noreferrer" target="_blank"><img src={WFTLogo} width="200px" alt="White Faux Taxidermy, Animal-friendly wall decorations."/></a>
            </Container>
            <Container fluid className="float-center">
            <Row className="justify-content-md-center">
            <Col md={6} sm className="border-right">
                <h3 className="descriptive-title">The Problem</h3>
                <p className="descriptive">In recent years, animal-themed decor has made a drastic comeback. However, much of the items available in the market are made using real animals.<br/>
                White Faux Taxidermy set to deliver both an animal-friendly product while maintaining aesthetics, clean lines, & modern home decor as unique as the animals they represent.
                </p>
            </Col>
            <Col md={6} sm className="border-right">
            <h3 className="descriptive-title">The Purpose</h3>
            <p className="descriptive">To increase animal-appreciation in a humane and affordable manner, while providing a completely customizable experience for even the most whimsical amongst us.</p>
            </Col>
            {/* <Col md={4} sm>
            <h3 className="descriptive-title">The Challenges</h3>
            <p className="descriptive"></p>
            </Col> */}
            </Row>
        </Container>
        <Jumbotron className="contrast-banner constrast">
              <h3 className="contrast-header contrast">Goal</h3>
                <Row className="justify-content-md-center contrast">
                    <Col md={4} sm>
                        <h4 className="contrast">Build a brand</h4>
                        <p>registered Trademark with USPTO: 'White Faux Taxidermy' to establish a brand recognized for more than just the color 'white'.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Create a story</h4>
                        <p>Our homes tell a story of who we are, whether it is Boho Chic, or Scandinavian minimalism. Animals have a home, too. The goal was to create a bridge for shoppers to share an experience with their faux animal.</p>
                    </Col>
                    <Col md={4} sm>
                        <h4 className="contrast">Easy checkout</h4>
                        <p>By accepting multiple payment methods, reducing hidden fees, like shipping; and offering incentives to drive conversions; we set to provide the purchaser a vision for what their home needs to make it their sanctuary.</p>
                    </Col>
                </Row>
            </Jumbotron>
            <Container className="container-row-project">
            <h1 className="left-align">The Product</h1>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <img src={WFTMobile} height="350px" title="WhiteFauxTaxidermy.com mobile shopping experience by Ricardo Shaffer" alt="WhiteFauxTaxidermy.com mobile shopping experience by Ricardo Shaffer"/>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Interface</h3>
                    <ul className="descriptive">
                        <li>Designed to be mobile-first.</li>
                        <li>Utilizing Analytics, the most popular/desired animals and colors are listed first.</li>
                        <li>Pagination versus unlimited scroll to reduce frustration & loss of spot. </li>
                        <li>Easy navigation across mobile & desktop to enable users to shop by their preferred method while maintaining SEO best practices.</li>
                    </ul>
                </Col>
            </Row>
            <Row className="justify-content-md-center border-bottom">
                <Col md={4} sm className="border-right">
                <img src={WFTDimensions} width="300px" title="White Faux Taxidermy Experience." alt="White Faux Taxidermy User Experience & Testimonials"/>
                </Col>
                <Col md={8} sm>
                    <h3 className="descriptive-title">Experience</h3>
                    <ul className="descriptive">
                        <li>Decluttered. The most desired info appears first (Dimensions, offers, and brief summary).</li>
                        <li>Include lifestyle photos of the most popular sculptures from actual clients to increase confidence</li>
                        <li>Dimensions are difficult, especially when shopping online. We set to create an easy-to-understand guide to increase satisfaction & reduce unwanted surprises. </li>
                    </ul>
                </Col>
            </Row>
        </Container>
        <Container>
        <Row>
            <Col md={6} sm className="border-right"><h3 className="descriptive-title">technologies Used:</h3>
                <ul className="descriptive">
                    <li><FontAwesomeIcon icon={['fab', 'shopify']} /> Built using Shopify</li>
                    <li><FontAwesomeIcon icon={['fab', 'google']} /> Google Ads & Analytics Optimized</li>
                    <li><FontAwesomeIcon icon={['fab', 'adobe']} /> Images edited & created using Adobe Suite</li>
                    <li><FontAwesomeIcon icon={['fab', 'amazon']} /> Amazon AWS used for CDN</li>
                    <li><FontAwesomeIcon icon={['fab', 'mailchimp']} /> email marketing & abandoned cart notification via MailChimp & JustUno</li>
                </ul>
            </Col>
            <Col md={6} sm>
                <Button variant="custom-btn"><a href="//whitefauxtaxidermy.com" rel="noopener noreferrer" target="_blank">Visit WhiteFauxTaxidermy</a></Button>
            </Col>
  </Row>
        </Container>
         </>
    )
}
export default WhiteFauxTaxidermy;